import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import dot from "../images/shapes/dot.svg"
import orange from "../images/shapes/orange.svg"
import content from "../content.json"
import { colors } from "../colors"

const Section = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 104px;
    width: 173px;
    top: 56px;
    left: -70px;
    z-index: -1;
  }

  @media only screen and (max-width: 1100px) {
    ::before {
      left: -121px;
    }
  }

  @media only screen and (max-width: 992px) {
    ::before {
      left: -44px;
      height: 49px;
      width: 90px;
      background-size: cover;
    }
  }
`

const Container = styled.div`
  margin: 48px auto;
  max-width: 1024px;
  display: flex;
`

const LeftCol = styled.div`
  flex: 100%;

  @media (min-width: 992px) {
    flex: 50%;
  }
`

const MainText = styled.div`
  max-width: 280px;
  margin: 0 auto;

  p {
    line-height: 1.5;
  }

  @media (min-width: 600px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 440px;
  }

  @media (min-width: 992px) and (max-width: 1092px) {
    padding-left: 24px;
  }
`

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 314px;
  margin: 0 auto;

  h5 {
    font-weight: normal;
  }

  @media (min-width: 600px) {
    max-width: 461px;
  }
`

const Box = styled.div`
  background-color: ${colors.bg1};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 150px;
  height: 110px;
  margin-bottom: 14px;
  line-height: 1.5;

  :nth-of-type(odd) {
    margin-right: 14px;
  }

  @media (min-width: 600px) {
    width: 220px;
    height: 145px;
    margin-bottom: 21px;

    :nth-of-type(odd) {
      margin-right: 21px;
    }
  }
`
const MobileImg = styled.div`
  display: none;
  position: relative;
  margin: 96px 0;
  ::before {
    content: "";
    position: absolute;
    background: url(${orange});
    background-repeat: no-repeat;
    height: 186px;
    width: 50%;
    top: -77px;
    z-index: -1;
    left: 15%;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    ::before {
      top: -62px;
    }
  }
`

const Dots = styled.div`
  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 52.31px;
    width: 97.72px;
    bottom: -60px;
    z-index: -1;
    right: 37%;
    background-size: cover;
  }
  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 43px;
    width: 37px;
    bottom: -16px;
    z-index: -1;
    background-size: cover;
    right: 44%;
  }
`

const DesktopImg = styled.div`
  text-align: right;
  position: relative;
  padding-top: 35px;
  display: none;

  ::before {
    content: "";
    position: absolute;
    background: url(${orange});
    background-repeat: no-repeat;
    height: 220px;
    width: 71%;
    top: -38px;
    z-index: -1;
    left: 80px;
    transform: translateX(-50px);
  }

  @media (min-width: 992px) {
    display: block;
    flex: 50%;
  }
`

const DesktopDots = styled.div`
  transform: translateX(-75px);

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 96px;
    width: 167px;
    bottom: 120px;
    z-index: -1;
    right: -113px;
  }
  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 54px;
    width: 50px;
    bottom: 199px;
    right: -58px;
    z-index: -1;
  }
`
export default function AboutHero() {
  return (
    <Section>
      <Container>
        <LeftCol>
          <MainText>
            <h2>{content.pages.saludpsic.heading}</h2>
            <p>{content.pages.saludpsic.text}</p>
          </MainText>
          <MobileImg>
            <Dots>
              <StaticImage
                src="../images/marialy/sobre-mobile.png"
                alt="person"
                placeholder="blurred"
              />
            </Dots>
          </MobileImg>

          <Boxes>
            <h5>{content.pages.saludpsic.beneficios.title}</h5>
            {content.pages.saludpsic.beneficios.beneficio.map(
              (beneficio, i) => (
                <Box key={i}>
                  {beneficio.part1}
                  <br />
                  {beneficio.part2}
                </Box>
              )
            )}
          </Boxes>
        </LeftCol>

        <DesktopImg>
          <DesktopDots>
            <StaticImage
              src="../images/marialy/sobre.png"
              alt="person"
              placeholder="blurred"
              style={{ borderRadius: "18px" }}
            />
          </DesktopDots>
        </DesktopImg>
      </Container>
    </Section>
  )
}
