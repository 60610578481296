import * as React from "react"
import content from "../content.json"
import AboutHero from "../components/AboutHero"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import Testimonial from "../components/Testimonial"

const Sobre = () => (
  <Layout>
    <Seo {...content.pages.saludpsic.seo}    />
    <AboutHero />
    <Testimonial>
      {content.pages.saludpsic.testimonial}
    </Testimonial>
  </Layout>
)

export default Sobre
