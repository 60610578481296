import pinkComma from "../images/shapes/pink-comillas.svg"
import { colors } from "../colors"
import styled from "styled-components";

const Testimonial = styled.div`
background: ${colors.bg2};
padding: 20px;
text-align: right;
color: ${colors.pinkText};
position: relative;
max-width: 252px;
margin: 16px auto;
margin-bottom: 70px;
line-height: 1.3;
font-weight: 600;

&::before {
  content: "";
  position: absolute;
  bottom: -36px;
  left: -30px;
  width: 65px;
  height: 65px;
  background: url(${pinkComma}) no-repeat;
  background-size: cover;
}

@media (min-width: 600px) {
  line-height: 1.5;
  max-width: 440px;
}

@media (min-width: 768px) {
  &::before {
    left: -40px;
    width: 86.5px;
    height: 81px;
  }
}
@media (min-width: 940px) {
  &::before {
    left: -65px;
    bottom: -56px;
    width: 131px;
    height: 124px;
  }
}
`

export default Testimonial;